import React from "react"
import { graphql } from "gatsby"
import Markov from "js-markov"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Rizbot extends React.Component {
  markov = new Markov()

  constructor() {
    super()
    this.state = {
      loading: true,
      numChars: 1000,
      order: 5,
      shortCutoff: 3,
      longCutoff: 15,
      text: "",
    }
  }

  initModel = async () => {
    const rizWords = await (await fetch("/rizwords.txt")).text()
    this.markov.addStates(rizWords.split("\n"))
    this.markov.train(this.state.order)
    this.setState({
      loading: false,
      text: this.markov.generateRandom(this.state.numChars),
    })
  }

  newPhrase = () => {
    let text = ""
    do {
      text = this.markov.generateRandom(this.state.numChars)
      console.log(text)
    } while (
      text.length > this.state.shortCutoff &&
      text.length < this.state.longCutoff
    )
    this.setState({ text })
  }

  onChange = e => {
    this.setState({ [e.target.name]: Number(e.target.value) })
    this.initModel()
  }

  componentDidMount = () => this.initModel()

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Rizbot" />
        <div style={{ textAlign: "center" }}>
          <h2>Rizbot</h2>
          {this.state.loading && "Training the Rizbot..."}
          <br />
          <strong>{this.state.text}</strong>
          <br />
          <br />
          <button onClick={this.newPhrase}>RIZ ME</button>
          <br />
          <br />
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "small",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label htmlFor="order">word clumps</label>
              <input
                name="order"
                value={this.state.order}
                onChange={this.onChange}
                type="number"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label htmlFor="shortCutoff">short phrase upper limit</label>
              <input
                name="shortCutoff"
                value={this.state.shortCutoff}
                onChange={this.onChange}
                type="number"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label htmlFor="longCutoff">long phrase lower limit</label>
              <input
                name="longCutoff"
                value={this.state.longCutoff}
                onChange={this.onChange}
                type="number"
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Rizbot

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
